import { createApp } from 'vue'
import 'normalize.css'
import 'milligram'
require("../node_modules/milligram/dist/milligram.min.css");
import App from './App.vue'
import router from './router'

import '@/assets/css/style.css';

createApp(App).use(router).mount('#app')
